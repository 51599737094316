<div class="container-fluid px-0">
  <!-- Header -->
  <header
    class="bg-purple text-white d-flex align-items-center justify-content-center"
    style="height: 75px"
  >
    <div
      *ngIf="timeLeft"
      class="square bg-yellow text-center py-3 d-none d-md-block px-3"
    >
      <p class="my-auto">{{ timeLeft }}</p>
    </div>
    <h1 class="my-auto">OFERTA por tiempo limitado!</h1>
  </header>
  <div
    *ngIf="timeLeft"
    class="square-mobile bg-yellow text-center py-2 d-block d-md-none"
  >
    <p class="my-auto">{{ timeLeft }}</p>
  </div>
</div>

<div class="container">
  <!-- Logo -->
  <div class="row justify-content-center">
    <div class="text-center mt-4 col-12 col-md-10">
      <img
        src="/assets/images/mootiva/logo.png"
        alt="Logo"
        class="img-fluid logo mx-auto my-4"
      />
    </div>
    <div class="col-md-8 text-center col-12">
      <h4
        class="subtitle"
        [innerHTML]="this.sanitizer.bypassSecurityTrustHtml(infoLanding?.title)"
      ></h4>
      <!-- <h4 class="subtitle">
        Descubre cómo ganar
        <span class="text-purple">+$3000 dólares </span> al mes en menos de 6
        semanas, trabajando
      </h4> -->
      <!-- <h4 class="subtitle">{{ infoLanding.name }}</h4> -->
    </div>
  </div>
  <div class="row my-5">
    <!-- Video -->
    <div
      class="col-12"
      *ngIf="
        infoLanding?.video?.youtube_link &&
        infoLanding.video.video_type == 'youtube'
      "
    >
      <iframe
        class="video-youtube"
        [src]="safeUrl"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      >
      </iframe>
    </div>
    <div
      *ngIf="infoLanding.video?.video_type == 'file'"
      class="col-12 preview-video"
    >
      <div
        class="thumbContainer"
        (click)="openVideoModal(infoLanding?.video?.id)"
        data-toggle="modal"
        [attr.data-target]="'#modalpreview-' + infoLanding?.video?.id"
      >
        <img
          [src]="infoLanding?.video?.thumbnail.replace('small', 'medium')"
          alt="Video thumbnail"
          class="video-youtube"
          onerror="this.src='assets/images/video_brok.jpg'"
          (load)="onImageLoad()"
        />
        <div class="playContainer">
          <img
            alt="Play button"
            class="playButton"
            src="assets/images/lesson/play.svg"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-12">
      <h2 class="my-5 text-instagram">
        {{ infoLanding?.data_json?.sale_title }}
      </h2>
    </div>
    <div class="col-md-9 col-12 my-2" *ngFor="let item of programToSell">
      <div class="card p-md-4">
        <div class="d-flex justify-content-start">
          <div class="col-auto pl-0">
            <img
              [src]="item?.image?.medium"
              onerror="this.src = '/assets/images/mootiva/logo-dark.png'"
              class="card-img"
              alt="Imagen descriptiva"
            />
          </div>
          <div class="col-md-8 col px-0">
            <h5 class="card-title mb-md-0">
              {{ item?.name | excerpt : 45 }}
            </h5>
            <p *ngIf="item?.price" class="text-subtitle-card mb-2">
              Valorado en
              <span class="text-purple"> usd {{ item?.price }}</span>
            </p>
            <p class="card-text mt-2">
              {{ cleanHtmlString(item?.description) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-center my-5">
    <div class="col-md-8 col-12 my-2">
      <div
        class="p-md-5 p-3"
        style="
          background-color: #f4f4f4;
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        "
      >
        <div class="row justify-content-center">
          <div class="col-auto">
            <h4 style="" class="card-title-bonus mb-1">
              <span
                class="text-purple"
                style="
                  text-decoration: underline;
                  text-decoration-thickness: 2px;
                "
                >Bonus</span
              >
              por tiempo limitado! {{ timeLeft }}
            </h4>
            <!-- <h5 style="" class="card-subtitle-bonus">
              Programa Duplicá tu comunidad de instagram
            </h5> -->
          </div>
          <div class="col-11 text-start">
            <p style="font-size: 18px" class="mt-2">
              {{ infoLanding?.data_json?.program_description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container text-center my-5">
  <h2
    style="text-decoration: line-through; font-size: 38px"
    class="text-purple"
  >
    usd {{ infoLanding?.data_json?.crossed_out_price }}
  </h2>
  <h3 style="color: #131f56; font-size: 42px">
    Solo por hoy
    <span class="text-purple">
      USD {{ infoLanding.data_json.program_sale_price }}</span
    >
  </h3>
  <div class="row justify-content-center">
    <div class="col-auto">
      <button class="btn btn-primary mt-3">
        {{ infoLanding?.thank_you_page_btn_text }}
      </button>
    </div>
  </div>
</div>
<div class="container mt-5">
  <div class="row justify-content-center">
    <div class="col-md-8">
      <h2 class="text-left" style="font-size: 24px">
        Seleccione el método de pago
      </h2>
      <hr style="border: 1px solid rgba(224, 224, 224, 1)" />

      <div class="d-flex jusitfy-content-start">
        <div class="col-auto payment-item">
          <img
            src="assets/images/logo-mp.png"
            alt="Logo MercadoPago"
            loading="lazy"
            decoding="async"
            class="provider-logo"
          />
        </div>
        <div class="col-auto payment-item mx-2 payment-selected">
          <img
            src="assets/images/logo-paypal.png"
            alt="Logo PayPal"
            loading="lazy"
            decoding="async"
            class="provider-logo"
          />
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container text-center my-5">
  <div class="d-flex justify-content-center">
    <div
      class="col-md-8 col-12 mt-5 text-left p-4"
      style="border: 1px solid rgba(224, 224, 224, 1); border-radius: 6px"
    >
      <p style="font-size: 16px; color: #333333">
        Seleccionaste
        <span style="font-weight: bold"> Paypal</span>
      </p>
      <p style="font-size: 16px; color: #333333" class="mb-5">
        Lorem lorem ipsum It is a long established fact that a reader will be
        distracted by the readable content of a page when looking at its layout.
      </p>
    </div>
  </div>
  <div class="d-flex justify-content-center">
    <div class="col-md-7">
      <hr class="my-4" />
    </div>
  </div>
  <div class="d-flex justify-content-center">
    <div class="col-md-8 col-12 p-4 text-left prices-card" style="">
      <h5 style="font-size: 20px; color: #333333">
        {{ infoLanding.data_json.title_additional_sale }}
      </h5>
      <p style="font-size: 16px; color: #333333">
        {{ infoLanding.data_json.description_additional_sale }}
      </p>

      <p style="font-size: 16px; color: #333333; font-weight: bold">
        <span style="text-decoration: line-through">
          Usd {{ infoLanding.data_json.price_crossed_additional_sale }}</span
        >
        valor por hoy usd
        {{ infoLanding.data_json.price_today_additional_sale }}
      </p>
      <div class="form-group">
        <div class="custom-control custom-checkbox my-2">
          <input
            type="checkbox"
            class="custom-control-input"
            [id]="'checkbox'"
            [checked]="isSelected()"
            (click)="toggleSelection(infoLanding.program_to_sell_data)"
          />
          <label
            class="custom-control-label text-purple mb-3"
            [for]="'checkbox'"
            >Agregar
            {{
              aditionalProducts.length == 1
                ? "elemento adicional"
                : "elementos adicionales"
            }}
          </label>
        </div>
        <div class="d-flex flex-column">
          <label
            *ngFor="let product of aditionalProducts; let i = index"
            class="text-purple"
            [for]="'checkbox' + i"
            >- {{ product?.name || product?.title }}
          </label>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container my-5">
  <div class="d-flex justify-content-center">
    <div class="col-md-8 col-12 px-0">
      <h2
        class="text-left"
        style="font-size: 18px; color: #333333; font-weight: bold"
      >
        Resumen de Compra
      </h2>
      <div class="summary border p-3 mt-3">
        <div class="d-flex justify-content-between">
          <span class="sumary-item"
            >{{ infoLanding?.program_to_sell_data?.name }}
          </span>
          <span class="sumary-item"
            >Usd ${{ infoLanding?.data_json?.program_sale_price }}</span
          >
        </div>
        <div class="d-flex justify-content-between" *ngIf="isSelected()">
          <span class="sumary-item">{{ "Programa adicional" }} </span>
          <span class="sumary-item"
            >Usd ${{ infoLanding.data_json.price_today_additional_sale }}</span
          >
        </div>
        <hr />
        <div class="d-flex justify-content-between">
          <strong>Total:</strong>
          <strong>Usd ${{ totalPrice }}</strong>
        </div>
      </div>
      <div class="row justify-content-start">
        <div class="col-md-auto col-12">
          <button class="btn btn-primary mt-3">Comprar</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal -->
<div
  class="modal fade"
  *ngIf="infoLanding.video.id"
  [id]="'modalpreview-' + infoLanding.video.id"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button
          #closeModal
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        <div class="playerWrap">
          <iframe
            *ngIf="videoLoaded"
            [src]="
              company.base_url + '/video-player/' + infoLanding.video.id | safe
            "
            style="width: 100%; border: none; aspect-ratio: 4/3"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal -->
